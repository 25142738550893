import React from 'react';
import { injectIntl, intlShape } from 'react-intl';

import { WebHeader } from '@cof/lighthouse-component-library';
import logoSource from '@cof/graffiti-alley-spray-cans/assets/images/c1-blue.svg';

import WebHeaderNavigation from '../WebHeaderNavigation';

import './Header.scss';

const Header = injectIntl(({ intl }) => {
  const bypassBlockProps = {
    mainContentIdForBypass: 'main',
    textForBypass: intl.formatMessage({ id: `navheader.bypass-block` }),
  };

  return (
    <WebHeader logoLink={logoSource} bypassBlockProps={bypassBlockProps} showMenuButtonOnMobile>
      <WebHeaderNavigation />
    </WebHeader>
  );
});

Header.propTypes = {
  intl: intlShape,
};

export default Header;
