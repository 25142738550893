import React from 'react';
import { intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Footer as PdpFooterRaw } from '@cof/lighthouse-component-library';

import WebFooterDefaultText from '../../WebFooterDefaultText';
import { getNavLinks } from '../../../lib/utils';
import { useConfig } from '../../../hooks/useConfig';

import './PdpFooterLH.scss';

const PdpFooterLH = ({ intl, hidePromo, promoSuper }) => {
  const config = useConfig(intl.locale);
  return (
    <PdpFooterRaw
      promoLink="https://creditkeeper.capitalone.ca/"
      nav={getNavLinks(intl, config.dapp)}
      hidePromo={hidePromo || intl.locale === 'fr'}
      copyrightText={<WebFooterDefaultText />}
      language={intl.locale}
      promoSuper={promoSuper}
    />
  );
};

PdpFooterLH.propTypes = {
  intl: intlShape,
  hidePromo: PropTypes.bool,
  promoSuper: PropTypes.number,
};

PdpFooterLH.defaultProps = {
  hidePromo: false,
  promoSuper: 3,
};

export default PdpFooterLH;
